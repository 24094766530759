import Libhoney from 'libhoney'
import cuid from 'cuid'

const base = {
  // NOTE(ibash) these come from the next.js build
  build_at: process.env.buildAt,
  build_id: process.env.buildId,
  service_name: 'hub',
  session_id: cuid()
}

const honey = new Libhoney({
  dataset: process.env.NEXT_PUBLIC_HONEYCOMB_DATASET,
  writeKey: process.env.NEXT_PUBLIC_HONEYCOMB_API_KEY
})

// TODO(ibash) at some point we should settle on naming conventions for metrics
honey.track = (name, data) => {
  const event = honey.newEvent()
  event.add(base)
  event.addField('client_timestamp', new Date().getTime())
  event.addField('name', name)
  event.add(data)

  if (typeof window !== 'undefined') {
    event.addField('pathname', new URL(location.href).pathname)
    event.addField('user_agent', navigator.userAgent)
  }

  event.send()
}

export default honey
